<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general/>
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>

    <!-- <b-tab v-if="['franchise'].includes(user_type)">
      <template #title>
        <feather-icon icon="DollarSignIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Monthly Expenses</span>
      </template>
      <MonthlyExpenses/>
    </b-tab> -->

    <b-tab v-if="['franchise'].includes(user_type)">
      <template #title>
        <feather-icon icon="UsersIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Counselor</span>
      </template>
      <StudentSupportList/>
    </b-tab>
    <b-tab v-if="['franchise','accounts'].includes(user_type)">
      <template #title>
        <feather-icon icon="AtSignIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Signatures</span>
      </template>
      <Signatures/>
    </b-tab>

    <b-tab v-if="['accounts','franchise'].includes(user_type)">
      <!-- title -->
      <template #title>
        <feather-icon icon="CreditCardIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Bank Accounts</span>
      </template>
      <BankAccounts/>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import MonthlyExpenses from "./MonthlyExpenses.vue";
import store from "@/store";
import BankAccounts from "./BankAccounts.vue";
import StudentSupportList from "../StudentSupports/StudentSupportList.vue";
import Signatures from "./Signatures.vue";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    MonthlyExpenses,
    BankAccounts,
    StudentSupportList,
    Signatures
  },
  data() {
    return {
    };
  },
  methods: {
    signatureFormatter(value) {
          if (value) {
            return `<img src="${this.FILESURL}${value}" height="50" width="250" />`;
          }
          return '';
        },
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      }
    },
  },
};
</script>
