import axiosInstance from "./Api";

export default {
  async getHomeData() {
    return await axiosInstance().get(`/counselor/home-data`);
  },

  async getAssignedAgents(payload) {
    return axiosInstance().get(`/counselor/assigned-agents`, {
      params: payload,
    });
  },

  async getAreaFranchise(params) {
    return axiosInstance().get(`/counselor/area-franchise`, {
      params,
    });
  },

  async getMicroFranchise(params) {
    return axiosInstance().get(`/counselor/micro-franchise`, {
      params,
    });
  },

  async saveProfileExtraInfo(payload) {
    let response = await axiosInstance().post(
      `/counselor/save-application-limit/${payload.student_user_id}`,
      payload
    );

    return response;
  },

  async suggestCourseStudent(payload) {
    const { course_id, student_user_ids } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/suggest-course/${course_id}`,
      { student_user_ids }
    );

    return response;
  },

  async removeSuggestion(payload) {
    const { student_user_id, course_id } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/${student_user_id}/remove-suggestion/${course_id}`
    );

    return response;
  },

  async setStudentConverted(payload) {
    const { student_user_id, convert_status } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/${student_user_id}/converted/${convert_status}`
    );

    return response;
  },

  async saveAgentCommissionForApplication(payload) {
    const { student_user_id } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/${student_user_id}/application-commission`,
      payload
    );

    return response;
  },

  async getStudentLeads(payload) {
    return await axiosInstance().get(`/counselor/student/leads`, {
      params: payload,
    });
  },

  async getFranchiseQuizResponse(payload) {
    const { agent_user_id } = payload;
    return await axiosInstance().get(
      `/counselor/franchise-quiz-responses/${agent_user_id}`,
      payload
    );
  },

  async getFranchiseList(payload) {
    return await axiosInstance().get(`/counselor/franchise-list`, {
      params: payload,
    });
  },

  async getAllStudentSupportList(params) {
    return axiosInstance().get(`/counselor/student-support`, { params });
  },

  async getStudentTransactions(params) {
    return axiosInstance().get(`/counselor/student-transactions`, { params });
  },

  async saveSoldBy(payload) {
    return axiosInstance().post(`/counselor/save-soldby`, payload);
  },

  async saveStudentTransaction(payload) {
    return axiosInstance().post(`/counselor/student-transaction`, payload);
  },

  async getAllTransactions(params) {
    return await axiosInstance().get(`/counselor/transactions`, { params });
  },

  async createStudent(payload) {
    return axiosInstance().post(`/counselor/create-student`, payload);
  },

  async recommendationByUniversity(payload) {
    const { university_id } = payload;
    return axiosInstance().get(
      `/counselor/recommended-students/${university_id}`,
      payload
    );
  },

  async saveServiceCommission(payload) {
    return await axiosInstance().post(
      "/counselor/save-service-commission",
      payload
    );
  },

  async getFranchiseStatistics(payload) {
    return axiosInstance().get(`/counselor/franchise-statistics`, payload);
  },

  async getServiceRevenue(payload) {
    const { franchise_id } = payload;
    return axiosInstance().get(`/counselor/service-revenue/${franchise_id}`);
  },

  async getAllFranchisesByHeirarchy(payload) {
    return axiosInstance().get(`/counselor/franchises-by-heirarchy`, {
      params: payload,
    });
  },
  async getFranchiseStatisticsByManagerId(manager_id) {
    return axiosInstance().get(`/counselor/franchise-statistics/${manager_id}`);
  },

  async getUserTypes() {
    return await axiosInstance().get(`/counselor/all-users-and-user-types`);
  },

  async getUtilityDetail(payload) {
    return await axiosInstance().get(`/counselor/utility-detail`, {
      params: payload,
    });
  },

  async checkSeniorManager(payload) {
    return await axiosInstance().get(`/counselor/check-senior-manager`, {
      params: payload,
    });
  },

  async getAllTeamManagers(payload) {
    return await axiosInstance().get(`/counselor/team-managers`, {
      params: payload,
    });
  },

  async getBankByFranchiseId(payload) {
    return await axiosInstance().get(`/counselor/franchise-bank`, {
      params: payload,
    });
  },

  async approveBank(payload) {
    return await axiosInstance().post(`/counselor/approve-bank`,payload);
  },

  async getBankApprovalHistory(payload) {
    return axiosInstance().get(`/counselor/bank-history`, {
      params: payload,
    });
  },


  // Events
  async getALlFranchiseEvents(payload) {
    return await axiosInstance().get(`/counselor/events`, {params:payload});
  },

  async saveEvents(payload) {
    return await axiosInstance().post(`/counselor/add-event`, payload);
  },

  async getAggregatedStats(payload){
    return await axiosInstance().get(`/counselor/franchise-aggregate-statistics`,{params:payload})
  },
  async getFranchiseStatistics(payload){
    return await axiosInstance().get(`/counselor/franchise-statistics`,{params:payload})
  },

  async getAllMonthlyExpenses(payload) {
    return await axiosInstance().get(`/counselor/franchise-all-monthly-expenses`, {params:payload});
  },
  async getMonthlyExpenses(payload) { 
    return await axiosInstance().get(`/counselor/franchise-monthly-expenses`, {params:payload});
  },
};
