<template>
  <div>
    <!-- Signarure -->
    <b-card
      title=" Digital Signature"
      v-if="['accounts', 'franchise'].includes(user_type)"
    >
      <div class="signature_button">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          @click="$bvModal.show('digital-signature-modal')"
        >
          Add
        </b-button>
      </div>
      <b-table
        v-if="signature_list.length > 0"
        :items="signature_list"
        :fields="signature_table_fields"
        show-empty
        empty-text="No Signature Available"
        responsive
      >
        <template #cell(sr_no)="{ index }">
          {{ index + 1 }}
        </template>
        <template #cell(sg_name)="data">
          <div class="">{{ data.item.sg_name }}</div>
        </template>
        <template #cell(sg_designation)="data">
          <div class="">{{ data.item.sg_designation }}</div>
        </template>
        <template #cell(sg_file_path)="data">
          <div class="">
            <img
              v-if="data.item.sg_file_path"
              :src="FILESURL + data.item.sg_file_path"
              height="50"
              width="250"
            />
          </div>
        </template>
      </b-table>
      <div v-else class="text-center">
        <h4>No Signature Available</h4>
      </div>
    </b-card>
    <!-- Signature Modal  -->
    <b-modal
      id="digital-signature-modal"
      centered
      title="Add Signature"
      hide-footer
      size="lg"
      ref="signature-modal-ref"
    >
      <b-row>
        <b-col sm="6">
          <b-form-group label="Name" label-for="name">
            <b-form-input
              v-model="signature.sg_name"
              placeholder="Name"
              name="name"
              id="name"
              class="mb-1"
            />
          </b-form-group>
          <b-form-group label="Designation" label-for="designation">
            <b-form-input
              v-model="signature.sg_designation"
              placeholder="Designation"
              name="designation"
              id="designation"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Image" label-for="image">
            <Cropper
              v-model="signature.sg_file_path"
              :width="200"
              :height="50"
              validatortype="image"
              imagetype="brand"
              label="Upload Signature"
              :title="signature.sg_name"
              :designation="signature.sg_designation"
              :name="signature.sg_name"
              @closeModal="closeModal"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-card title="Email Signature" v-if="['franchise'].includes(user_type)">
      <div class="signature_button" style="gap: 5px">
        <b-button
        variant="primary"
        size="sm"
        @click="$bvModal.show('signature-modal')"
        >
          Add
        </b-button>
      </div>
      <div class="" style="width: 300px;">
        <div v-html="email_signature" class="mr-3"></div>
        <div v-if="!email_signature"> No Signature Available</div>
      </div>

      <b-modal
        :no-close-on-backdrop="true"
        id="signature-modal"
        centered
        hide-footer
        title="Add Signature"
      >
        <quill-editor
          id="quil-content"
          :options="editorOption"
          v-model="email_signature"
        />
        <div class="d-flex justify-content-end">
          <b-button @click="saveLMSUserDetails" variant="primary" class="mt-1"
            >Save</b-button
          >
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
  BTable,
} from "bootstrap-vue";
import Cropper from "./Cropper.vue";
import store from "@/store";
import CommonServices from "@/apiServices/CommonServices";
import { FILESURL } from "@/config";
import { quillEditor } from "vue-quill-editor";
import LmsServices from "@/apiServices/LmsServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: "Signatures",
  components: {
    BRow,
    BCol,
    BFormGroup,
    Cropper,
    BCard,
    BModal,
    BButton,
    BTable,
    quillEditor,
    BFormInput
  },
  data() {
    return {
      signature: {
        sg_name: "",
        sg_designation: "",
        sg_file_path: "",
      },
      signature_list: [],
      signature_table_fields: [
        { key: "sr_no", label: "Sr. No." },
        { key: "sg_name", label: "Name" },
        { key: "sg_designation", label: "Designation" },
        { key: "sg_file_path", label: "Signature" },
      ],
      FILESURL,
      showSignatureModal: false,
      lmsApi: null,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["link", "image"],
          ],
        },
        placeholder: "",
      },
      email_signature: "",
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        const user_type = store.getters["user/getUserDetails"].user_type;
        // if (user_type === "accounts" ) {
        //   this.getSignature();
        // }
        return user_type;
      } else {
        return null;
      }
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('signature-modal');
      this.getSignature();
    },
    async getSignature() {
      try {
        const res = await CommonServices.getSignature();
        if (res.data.status) {
          this.signature_list = res.data.data;
        }
      } catch (error) {
        console.error("Error in getSignature", error);
      }
    },

    async getSignatureById(signature_id) {
      try {
        const res = await CommonServices.getSignatureById(signature_id);
        if (res.data.status) {
          this.signature = res.data.data;
        }
      } catch (error) {
        console.error("Error in getSignatureById", error);
      }
    },

    async getEmailSignature() {
      try {
        const res = await this.lmsApi.getUserProfile();
        if (res.data.status) {
          console.log(res);
          this.email_signature = res.data.data.user.user_signature_html;
        }
      } catch (error) {}
    },
    async saveLMSUserDetails() {
      try {
        const res = await this.lmsApi.saveUserProfile({
          user_signature_html: this.email_signature,
        });
        if (res.data.status) {
          this.showSignatureModal = false;
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Signature Saved",
              icon: "Check",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Failed to Save Signature",
              icon: "XIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getLMSAccess() {
      try {
        const res = await CommonServices.getLMSAccessToken();
        this.accessToken = res.data.data;

        if (!this.accessToken) {
          this.accessTokenFailed = true;
          return;
        }

        this.lmsApi = LmsServices(this.accessToken);
        this.getEmailSignature();
      } catch (error) {
        console.error(`Error in getLMSAccess `, error);
      }
    },
  },
  beforeMount() {
    if(this.user_type && (this.user_type === "accounts" || this.user_type === "franchise")){
    this.getSignature();
    }
    this.getLMSAccess();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
<style>
.signature_button {
  position: absolute;
  right: 15px;
  top: 15px;
}
</style>
